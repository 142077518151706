
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import './Slider.css'
// import required modules
import { Pagination,Autoplay } from "swiper";
import {baseURL,sliderImagePath} from '../../Config';
import axios from "axios";
import React,{ useState ,useEffect} from 'react';




export default function App() {


const [sliderList, setSliderList] = useState(null);
const access_token = JSON.parse(localStorage.getItem('access_token'));
const getSliderListURL='api/customer/sliders'
const getSliderList=async()=>{
  try {
      const res = await axios.get(baseURL+getSliderListURL , { headers: {"Authorization" : `Bearer ${access_token}`} })
      if(res.data.success)
      {
          //got all the slider list here
          setSliderList(res.data.data);
      }
      else{
          alert("Error Contact Administrator")
      }
  } catch (error) {
      return( error)
  }
  }


useEffect(() => {
       
  getSliderList();
    
      }, [])
    

  return (
    <>
      <Swiper
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          dynamicBullets: true,
          clickable:true,
        }}
        modules={[Pagination,Autoplay]}
        loop={true}
        className="mySwiper"
      >
        {sliderList?sliderList.map((slider)=><SwiperSlide key={slider.id}  ><img src={sliderImagePath+slider.slider_image} className="d-block w-100 mySwiperIMG"  alt={slider.title}/>  </SwiperSlide> ):null}

       
      </Swiper>
    </>
  );
}
