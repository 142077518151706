import React, { useEffect,useState }from 'react'
import {useSelector,useDispatch} from "react-redux"
import HashLoader from "react-spinners/HashLoader";
import Slider from '../Slider/Slider';
import SideFilterBar from './SideFilterBar/SideFilterBar';
import {baseURL,noDataFoundimg} from '../../Config';
import {set_isLoading} from "../../Redux/isLoading/isLoadingSlice"
import axios from 'axios';
import SelectLocationModal from '../SelectLocationModal/SelectLocationModal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import VendorNameSection from './VendorNameSection/VendorNameSection';
import VendorFlyerSection from './VendorFlyerSection/VendorFlyerSection';
import './Home.css'
import CopyRightAndTerms from './CopyRightAndTerms/CopyRightAndTerms';
function Home() {

const isLoading = useSelector(state => state.isLoading.isLoading);
const loadLocation = useSelector(state => state.loadLocation.loadLocation);

const dispatch =useDispatch()
//get access token
const access_token = JSON.parse(localStorage.getItem('access_token'));
//location value
const location_value = JSON.parse(localStorage.getItem('location_value'));

const [optionShopCatg,setOptionShopCatg]=useState([{value:null,label:'All Category'}])
const [selectedOptionShopCatg,setSelectedOptionShopCatg]=useState(null)
const [selectedOptionShopLabel,setSelectedOptionShopCatgLabel]=useState(null)

const [selectedOptionShopBoth,setSelectedOptionShopBoth]=useState(null)



const getShopCatgListURL='api/vendor/category/shop'
const getShopCatgList=async()=>{
  try {
      const res = await axios.post(baseURL+getShopCatgListURL ,{},{ headers: {"Authorization" : `Bearer ${access_token}`} })
      if(res.data.success)
      {
          setOptionShopCatg([{value:null,label:'All Category'}])
          res.data.data.map((shopCatg)=>( setOptionShopCatg(optionShopCatg=>[...optionShopCatg,{value:shopCatg.id, label:shopCatg.title}])   ))        
      }
      else{
          alert("Error Contact Administrator")
      }
  } catch (error) {
      return( error)
  }
  }

  const handleChangeCatg = (event) => {
    setSelectedOptionShopCatg(event.value)
    setSelectedOptionShopCatgLabel(event.label);
    setSelectedOptionShopBoth(event)
    getCustomerFlyerListOnCatgChange(access_token,0,12,event.value)
  };

  const [noDataFound,setNoDataFound]=useState(false);


const [flyerData, setFlyerData] = useState([])
const getCustomerFlyerListURL='api/customer/flyers'
const getCustomerFlyerList = async (token,start,length) => 

{
  if(location_value)
  {
        dispatch(set_isLoading({isLoading:true}))
    try {
        const response = await axios.post(baseURL+getCustomerFlyerListURL,
          {state:location_value.businessState,
            district:location_value.businessDistrict,
              location:location_value.location,
              start:(start*12),length:length
                },{ headers: {"Authorization" : `Bearer ${token}`} });
        dispatch(set_isLoading({isLoading:false}))

       
        if(response.data.success)
        {
          if(response.data.data.length!==0)
          {
            setFlyerData(response.data.data)
            setNoDataFound(false)
          }
          else
          {
            setNoDataFound(true)
          }
        }
        else
        {
          setNoDataFound(true)
        }
      
        }
    catch(error){
        dispatch(set_isLoading({isLoading:false}))
        console.log(error);
        return(error) 
    }
    }
}


const getCustomerFlyerListOnCatgChange = async (token,start,length,shopCatg) => 

{
  if(location_value)
  {
        dispatch(set_isLoading({isLoading:true}))
    try {
        const response = await axios.post(baseURL+getCustomerFlyerListURL,
          {state:location_value.businessState,
            district:location_value.businessDistrict,
              location:location_value.location,
              shop_category:shopCatg,
              start:(start*3),length:length
                },{ headers: {"Authorization" : `Bearer ${token}`} });
        dispatch(set_isLoading({isLoading:false}))

       
        if(response.data.success)
        {
          if(response.data.data.length!==0)
          {
          
            setFlyerData(response.data.data)
            setNoDataFound(false)
          }
          else
          {
            setNoDataFound(true)
          }
        }
        else
        {
          setNoDataFound(true)
        }
      
        }
    catch(error){
        dispatch(set_isLoading({isLoading:false}))
        console.log(error);
        return(error) 
    }
    }
}




useEffect(() => {

     
    if(selectedOptionShopCatg===null)
    {
      getShopCatgList()
    }
    getCustomerFlyerList(access_token,0,12)

    setSelectedOptionShopBoth({value:null,label:'All Category'})
   }, [loadLocation])


   
  const handleCloseNavMenu = () => {
    
    };


  return (
    <>

{location_value?null:<SelectLocationModal/>}

  <div className={isLoading ? 'parentDisableCommon' : ''} width="100%">

  <HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
</div>
    
    <div className='container-fluid'>
      <div className='row d-flex justify-content-center'>
        <Slider/>
      </div>
    </div>

    <div className='container-fluid bg-light'>
      <div className='row d-flex'>

       <div className='col-md-3 d-none d-xl-block d-lg-block d-md-block'>
        <SideFilterBar closeNavMenuFunction={handleCloseNavMenu} />

        <CopyRightAndTerms/>
       </div>


       <div className='col-md-9'>
        <div className='row mt-2'>
                  <div className='col-6'>
              
                      <div >
                        <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={optionShopCatg}
                              value={selectedOptionShopBoth}
                              defaultValue={optionShopCatg[0]}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              sx={{ width: 300,mt:1 }}
                              onChange={(event, newValue) => {                                   
                                          handleChangeCatg(newValue)
                                                }}
                              renderInput={(params) => <TextField {...params} label="Shop Category" />}
                            />
                        </div>
                

                  </div>
        </div>

        {noDataFound?
               <div className='col-9 p-3 d-flex align-items-stretch justify-content-center m-auto'>
               <div className="d-flex flex-column">
                 <div className="p-2"><img src={noDataFoundimg} alt={'no Data Found'} style={{maxWidth:'100%',maxHeight:'450px'}}/></div>
                 <div className="p-2 text-center"> <h5>No Data Found !  </h5></div>
               </div>
         
             </div>:
        <>
        
        <div className='row'>

          <VendorNameSection flyerData={flyerData}  selectedOptionShopCatg={selectedOptionShopCatg} />

        </div>

        <div className=''>
          <VendorFlyerSection  flyerData={flyerData}  selectedOptionShopCatg={selectedOptionShopCatg}  />

         </div>
        </>
        
        }



      </div>
      </div>
    </div>

    
    </>
    
  )
}

export default Home