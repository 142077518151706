import React ,{useEffect,useState}from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Avatar from '@mui/material/Avatar';
import { Pagination } from "swiper";
import { baseURL, businessImagePath,flyerImagePath} from '../../../Config';
import  {CaretUpFill,RocketTakeoff,HourglassSplit} from 'react-bootstrap-icons';

import Typography from '@mui/material/Typography';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollToTop from "react-scroll-to-top";
import GetLocation from './GetLocation';
import { useNavigate } from 'react-router-dom';
function VendorFlyerSection({flyerData,selectedOptionShopCatg}) {

    const [newFlyerData, setNewFlyerData] = useState([])
    const [nextPage, setNextPage] = useState(1)
    const [loadMore,setLoadMore]=useState(true)

const getPlanDuration =(end_date)=> {
      var dt1 = new Date();
      var dt2 = new Date(end_date);
      
      return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
      }

const navigate =useNavigate()
const showDetailsOfFLyer=(flyerID,flyerVendorID,flyerTitle,flyerStartDate,flyerVendorShopName)=>
      {
        navigate(`flyers/${flyerVendorShopName}/${flyerVendorID}/${flyerTitle}/${flyerID}/${flyerStartDate}`)
      }

      

//location value
const location_value = JSON.parse(localStorage.getItem('location_value'));
//get access token
const access_token = JSON.parse(localStorage.getItem('access_token'));
const getCustomerFlyerListURL='api/customer/flyers'
const getCustomerFlyerList = async (token,start,length) => 

{
  if(location_value)
  {
    try {
        const response = await axios.post(baseURL+getCustomerFlyerListURL,
          {state:location_value.businessState,
            district:location_value.businessDistrict,
              location:location_value.location,
                shop_category:selectedOptionShopCatg,
              start:(start*12),length:length
                },{ headers: {"Authorization" : `Bearer ${token}`} });
       
      
        if(response.data.success)
        {
          if(response.data.data.length!==0)
          {
      
            setNextPage(nextPage => nextPage + 1);
            response.data.data.map((x)=>{  setNewFlyerData( prevnewFlyerData => [...prevnewFlyerData,x]  ) })
          }
          else
          {
            setLoadMore(false)
         
          }
        }
        else
        {
           
        }
      
        }
    catch(error){
        
        console.log(error);
        return(error) 
    }
    }
}

useEffect(() => {
    

    if(flyerData.length!==0)
        {
            setNewFlyerData(flyerData)
        }
       
        setNextPage(1);
    
       }, [flyerData,selectedOptionShopCatg]) 


  return (

    <>
     <ScrollToTop smooth   viewBox="0 0 24 24"  component={<CaretUpFill color='#1976d2'/>}
  />
    {(newFlyerData.length!==0)?
    <InfiniteScroll
        //  dataLength={flyerData[0].totalRecordsWithFilter}
         dataLength={newFlyerData.length}
         next={()=>{ getCustomerFlyerList(access_token,nextPage,12)}}
         hasMore={loadMore}
         loader={<p style={{ textAlign: 'center' ,color:'#1976d2' }}>
          <HourglassSplit/>   
       </p>}
         endMessage={
            <p style={{ textAlign: 'center' ,color:'#1976d2' }}>
              <b> <RocketTakeoff/>   Yay! You have seen it all</b>
            </p>
          }>
            <div className='row'>
            {newFlyerData
            .filter(vendor => vendor.admin_approved_vendor === '1')
            .map((vendor, index) => {
              // Find the first flyer with status 'active'
              const activeFlyer = vendor.flyers.find(flyer => flyer.status === 'active');

              // If there's no active flyer, return null (or handle it as needed)
              if (!activeFlyer) return null;

              return (
                <div
                  className='col-xl-4 col-lg-4 col-md-6 col-12 p-3 d-flex align-items-stretch justify-content-center'
                  key={index}
                  style={{ cursor: 'pointer' }}
                >
                  <Card
                    sx={{ maxWidth: 331, width: 331 }}
                    onClick={() => {
                      showDetailsOfFLyer(
                        activeFlyer.id,
                        activeFlyer.vendor_id,
                        activeFlyer.title,
                        activeFlyer.start_date,
                        vendor.shop_name
                      );
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          style={{ margin: 'auto', borderRadius: '10%' }}
                          alt={vendor.shop_name}
                          src={businessImagePath + vendor.business_logo}
                          sx={{ width: 48, height: 48, bgcolor: '#fff' }}
                        />
                      }
                      title={vendor.shop_name}
                      subheader={vendor.location}
                    />
                    <CardMedia
                      component="img"
                      height="468"
                      image={`${flyerImagePath}${activeFlyer.thumb_image}`}
                      alt={activeFlyer.title}
                    />
                    <CardContent>
                      <h5 className='text-break'>{activeFlyer.title} </h5>
                      <Typography variant="body2" color="text.secondary ">
                        <span className="d-inline-block text-truncate" style={{ maxWidth: '100%' }}>
                          {activeFlyer.description}
                        </span>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <span style={{ color: "brown" }}>
                          {getPlanDuration(activeFlyer.end_date)} Days Left
                        </span>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}

            </div>
           

    </InfiniteScroll>:null}
    </>
  )
}

export default VendorFlyerSection