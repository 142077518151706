import {createSlice } from "@reduxjs/toolkit"

export const loadLocationSlice= createSlice ({
    name : "loadLocation",
    initialState :{
        loadLocation:false,
        
    },

    reducers:{

        set_loadLocation : (state,{payload})=>{
            state.loadLocation=payload.loadLocation     
        },
      
    }
})

export const {set_loadLocation} = loadLocationSlice.actions

export default loadLocationSlice.reducer