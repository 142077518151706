import React from 'react'
import { aboutusIMG } from '../../Config'

function AboutUS() {
  return (
    <div className='container-fluid bg-primary d-flex justify-content-center'>
      <div className='col-xl-6 col-lg-6  col-12'>
          
          <div className=' text-white p-4 '>
          <h4 className='text-center'>About Us</h4>
          <h5 className='text-center' > IWILLFLY</h5>
          <div className='text-center'>
          <img src={aboutusIMG} alt='contact-use' className='m-auto' style={{maxWidth:"100%"}}/>
            </div>   
       
         
            <p className='text-center' style={{fontSize:'0.8rem'}}>
             IWILLFLY
               is a Professional Advertisment
               Platform. Here we will provide you only interesting content, which you will like very much. 
               We're dedicated to providing you the best of Advertisment, with a 
               focus on dependability and Flyer upload and see. We're working to 
               turn our passion for Advertisment into a booming  We hope you enjoy our 
             Advertisment as much as we enjoy offering them to you.
Please give your support and love
Thanks For Visiting Our Site
Have a nice day!</p>

             
            
          </div>
      </div>
    </div>
  )
}

export default AboutUS