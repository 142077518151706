import logoBlueRse from './assests/images/logoBlueRse.png';
import logowhite from './assests/images/logo.png';
import logoPngImng from './assests/images/loginPageImg.jpg';
import nodataFound from './assests/images/nodataFound.png'
import businessimg from './assests/images/businessImage.jpg'
import contactusimg from './assests/images/contactus.png'
import aboutusimg from './assests/images/aboutus.png'


//blue logo
export const  logoBlue = logoBlueRse;
//white logo
export const  logo = logowhite;

//cotnact logo
export const  contactusIMG = contactusimg;

//about logo
export const  aboutusIMG = aboutusimg;




//logo png
export const  logoPngImg = logoPngImng;
export const businessImg = businessimg



export const noDataFoundimg = nodataFound


//base url
export const  baseURL = 'https://admin.iwillfly.in/'

//image path
export const  flyerImagePath = 'https://admin.iwillfly.in/public/storage/flyers/'

//busineess Image Path 
export const  businessImagePath = 'https://admin.iwillfly.in/public/storage/business/'


//slider image path

export const sliderImagePath='https://admin.iwillfly.in/public/storage/slider/'