import React from 'react'
import  {Headset} from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
function CopyRightAndTerms() {
  return (


    
   <>
   <div className=''>
    
    <p className='text-secondary text-center mt-1 text-break m-0' style={{fontSize:'0.6rem'}}>Copyright © 2023 IWILLFLY  
    |<Link to="/terms-conditions" className='text-decoration-none'>Terms and Conditions </Link>|
    <Link to="/refund-policy" className='text-decoration-none'>Privacy & Refund Policy</Link>|
    </p>
    <p className='text-secondary text-center mt-1 text-break m-0' style={{fontSize:'0.6rem'}}>
    <Link to="/about-us" className='text-decoration-none'>About Us </Link>|
    <Link to="/contact-us" className='text-decoration-none'> Contact Us </Link>


     
     
      </p>
    <p className='text-secondary text-center  text-break m-0' style={{fontSize:'0.8rem'}}>
      <a href="tel:+918129654111" className="ms-1 text-decoration-none">
      <Headset/> Call Us +91 8129 654 111 </a> 
      {/* <a href="mailto:support@iwillfly.com?subject=Contact Us" className="ms-1">
      Contact Us
      
      </a>  */}
    </p>
    <p className='text-secondary text-center text-break m-0' style={{fontSize:'0.8rem'}}>
      
    </p>
    
   </div>
   </>
    

  )
}

export default CopyRightAndTerms