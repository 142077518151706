import React from 'react'
import './ContactUs.css'
import  {TelephoneFill,EnvelopeFill} from 'react-bootstrap-icons';
import { contactusIMG } from '../../Config';
function ContactUs() {
  return (
    <>
    <div className='container-fluid bg-primary d-flex justify-content-center'>
      <div className='col-xl-6 col-lg-6  col-12'>
          
          <div className=' text-white p-4 '>
          <h4 className='text-center'>Contact Us</h4>
          <p className='text-center' style={{fontSize:'0.8rem'}}>Have a question for us, or feedback? Please click on the most appropriate option below and fill out the form to reach us.</p>
          <div className='text-center'>
          <img src={contactusIMG} alt='contact-use' className='m-auto' style={{maxWidth:"100%"}}/>
            </div>   
          

             
             
              <p><TelephoneFill size={26}/>
              <a href="tel:+918129654111" className="ms-3 text-decoration-none text-white">
               +91 8129 654 111 </a>  </p>

              <p>
                <EnvelopeFill size={26}/>
                <a href="mailto:support@iwillfly.com?subject=Contact Us" className="ms-3  text-decoration-none text-white">
                support@iwillfly.com
      
                 </a> 
              </p>
              <p>Address :IWILL MEDIA ,44/1256,South Janatha Road , Kochi -25 </p>
          </div>
      </div>
    </div>
    </>
  )
}

export default ContactUs