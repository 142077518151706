import React ,{useEffect,useState}from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Avatar from '@mui/material/Avatar';
import { Pagination } from "swiper";
import { baseURL, businessImagePath ,flyerImagePath} from '../../../Config';

import Typography from '@mui/material/Typography';
import axios from 'axios';
import './VendorNameSection.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import { useNavigate } from 'react-router-dom';
function VendorNameSection({flyerData,selectedOptionShopCatg}) {

const [newFlyerData, setNewFlyerData] = useState([])
const [nextPage, setNextPage] = useState(1)

const navigate =useNavigate()
const showDetailsOfFLyer=(flyerID,flyerVendorID,flyerTitle,flyerStartDate,flyerVendorShopName)=>
      {
        navigate(`flyers/${flyerVendorShopName}/${flyerVendorID}/${flyerTitle}/${flyerID}/${flyerStartDate}`)
      }

const getPlanDuration =(end_date)=> {
        var dt1 = new Date();
        var dt2 = new Date(end_date);
        
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
        }
//location value
const location_value = JSON.parse(localStorage.getItem('location_value'));
//get access token
const access_token = JSON.parse(localStorage.getItem('access_token'));
const getCustomerFlyerListURL='api/customer/flyers'
const getCustomerFlyerList = async (token,start,length) => 
{
  
  if(location_value)
  {
    try {
        const response = await axios.post(baseURL+getCustomerFlyerListURL,
          {state:location_value.businessState,
            district:location_value.businessDistrict,
              location:location_value.location,
                shop_category:selectedOptionShopCatg,
              start:(start*12),length:length
                },{ headers: {"Authorization" : `Bearer ${token}`} });
        if(response.data.success)
        {
          
          if(response.data.data.length!==0)
          {
            
            setNextPage(nextPage => nextPage + 1);
            response.data.data.map((x)=>{   setNewFlyerData( prevnewFlyerData => [...prevnewFlyerData,x]  ) })
      
          }
          else
          {
            console.log('');
          }
        }
        else
        {
            console.log('lresponse fialedf');
        }
      
        }
    catch(error){
        
        console.log(error);
        return(error) 
    }
    }
}

const [flyerListOfAVendor, setFlyerListOfAVendor] = useState(null)
const getCustomerFlyerListOfAVendor = async (vendor_id) => 
{
  if(location_value)
  {
    try {
        const response = await axios.post(baseURL+getCustomerFlyerListURL,
          {state:location_value.businessState,
            district:location_value.businessDistrict,
              location:location_value.location,
                vendor_id:vendor_id,
                  start:(0),length:12
                },{ headers: {"Authorization" : `Bearer ${access_token}`} });
       

        if(response.data.success)
        {
          if(response.data.data.length!==0)
          {           
            setFlyerListOfAVendor(response.data.data)
          }
          else
          {
            setFlyerListOfAVendor(null)
           
          }
        }
        else
        {
            setFlyerListOfAVendor(null)
            console.log('lresponse fialedf');
        }
      
        }
    catch(error){
        
        console.log(error);
        return(error) 
    }
    }
}



   useEffect(() => {
   
    if(flyerData.length!==0)
    {
        setNewFlyerData(flyerData)
    }
    setFlyerListOfAVendor(null);
    setNextPage(1);

   }, [flyerData,selectedOptionShopCatg])
    
  return (

    <div className='mt-2'>     
          

    {(newFlyerData.length!==0)?

     <Swiper 
     onSlideChange={() => console.log('')}
     onReachEnd={() => {  getCustomerFlyerList(access_token,nextPage,12)   }}
    breakpoints= {{
        // when window width is >= 320px
        290: {
        slidesPerView: 5,
        spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
        slidesPerView: 8,
        spaceBetween: 20
        },
        // when window width is >= 640px
        640: {
        slidesPerView: 8,
        spaceBetween: 20
        },
        // when window width is >= 640px
        920: {
              slidesPerView:10,
              spaceBetween: 20
        },
        1200: {
          slidesPerView:10,
          spaceBetween: 20
                }
        }}
    pagination={{
      clickable: true,
      dynamicBullets: true,
  
    }}
    modules={[Pagination]}
    className="mySwiper"
  >

{(newFlyerData.length !== 0) ? 
  newFlyerData
    .filter(vendor => vendor.admin_approved_vendor === "1" && vendor.status === "active")
    .map((vendor, index) => (
      <div key={index} className='text-center'>
        <SwiperSlide key={index} onClick={() => getCustomerFlyerListOfAVendor(vendor.vendor_id)} style={{ cursor: 'pointer' }}>
          <Avatar 
            style={{ margin: 'auto', borderRadius: '10%' }} 
            alt={vendor.shop_name} 
            src={businessImagePath + vendor.business_logo} 
            sx={{ width: 48, height: 48, bgcolor: '#fff' }} 
          />
          <Typography className='text-center text-truncate' style={{ fontSize: '0.8rem' }}>
            {vendor.shop_name}
          </Typography>
        </SwiperSlide>
      </div>
    ))
: null}
  
   


  </Swiper>:null}

      {flyerListOfAVendor?(flyerListOfAVendor.length!==0)?
      <>
      <div className='row'>
      {flyerListOfAVendor[0].flyers
                .filter((flyer) => flyer.admin_approved === "1" && flyer.status === "active") // Combined filter condition
                .map((flyer,index)=>(
      
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 p-3 d-flex align-items-stretch justify-content-center' key={index}>
            <Card sx={{ maxWidth: 331 ,width:331 }}  onClick={()=>{showDetailsOfFLyer(flyer.id,flyerListOfAVendor[0].vendor_id,flyer.title,flyer.start_date,flyerListOfAVendor[0].shop_name)}} >
                <CardHeader
                avatar={
                  <Avatar style={{margin:'auto',borderRadius:'10%'}}  alt={flyerListOfAVendor[0].shop_name} src={businessImagePath+flyerListOfAVendor[0].business_logo} sx={{ width: 48, height: 48 , bgcolor: '#fff' }}/>  
                }
                title={flyerListOfAVendor[0].shop_name}
                  
                // subheader={<GetLocation  location={flyerList.location}   />}
                
                subheader={flyerListOfAVendor[0].location}

                />
                <CardMedia
                component="img"
                height="468"
                image={`${flyerImagePath}${flyer.thumb_image}`}
                alt={flyer.title}
                />
                <CardContent>
                <h5 className='text-break'>{flyer.title} </h5>
                <Typography variant="body2" color="text.secondary ">
                <span className="d-inline-block text-truncate" style={{maxWidth: '100%'}}>
                {flyer.description}
                </span>  
                </Typography>
                    <Typography variant="body2" color="text.secondary">
                    <span style={{color:"brown"}}> {getPlanDuration(flyer.end_date)}   Days Left</span>
                    </Typography>
                </CardContent>


            </Card>
            </div>

      ))}
      </div>
      </>:null:null}


  </div>
  )
}

export default VendorNameSection