import {configureStore } from "@reduxjs/toolkit"
import userAuthenticationReducer from "./userAuthentication/userAuthenticationSlice"
import isLoadingReducer from "./isLoading/isLoadingSlice"
import loadLocationReducer from "./loadLocation/loadLocationSlice"


export default configureStore({
    reducer:{
        userAuthentication:userAuthenticationReducer,
        isLoading:isLoadingReducer,
        loadLocation:loadLocationReducer
   
    }
})