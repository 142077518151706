import { useParams } from 'react-router-dom'
import { Swiper, SwiperSlide, } from "swiper/react";
import React,{useState,useEffect} from 'react';
import {baseURL,flyerImagePath,noDataFoundimg,businessImagePath,businessImg} from '../../Config';
import {set_isLoading} from "../../Redux/isLoading/isLoadingSlice"
import axios from 'axios';
import {useSelector,useDispatch} from "react-redux"
import HashLoader from "react-spinners/HashLoader";
import  {ShareFill,ArrowLeft} from 'react-bootstrap-icons';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/navigation";
// import required modules
import { Zoom, Pagination ,Navigation} from "swiper";
import { RWebShare } from "react-web-share";
import {set_loadLocation} from "../../Redux/loadLocation/loadLocationSlice"
function FlyerDetails() {

  const navigate =useNavigate()
  const showDetailsOfFLyer=(flyerID,flyerVendorID,flyerTitle,flyerStartDate,flyerVendorShopName)=>
  {
    // window.location.href='/your-href'
      dispatch(set_loadLocation({loadLocation:!loadLocation}))
     navigate(`/flyers/${flyerVendorShopName}/${flyerVendorID}/${flyerTitle}/${flyerID}/${flyerStartDate}`)
  }

  const { flyerVendorShopName,vendorId ,flyerTitle,flyerID,flyerStartdate} = useParams();
  const isLoading = useSelector(state => state.isLoading.isLoading);
  const loadLocation = useSelector(state => state.loadLocation.loadLocation);

  const getPlanDuration =(end_date)=> {
    var dt1 = new Date();
    var dt2 = new Date(end_date);
    
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
    }

  const dispatch =useDispatch()
  const [noDataFound,setNoDataFound]=useState(false);
  const access_token = JSON.parse(localStorage.getItem('access_token'));
  const getFlyerDetailsURL ='api/customer/flyers/details'
  const [flyerDetails, setFlyerDetails] = useState(null)
  
  const getFlyerDetails = async (token) => 
  {
      dispatch(set_isLoading({isLoading:true}))
      try {
          const response = await axios.post(baseURL+getFlyerDetailsURL,
            {flyer_id:flyerID},{ headers: {"Authorization" : `Bearer ${token}`} });
      

          if(response.data.success)
          {
            setFlyerDetails(response.data.data)
            getVendorDetails(token,response.data.data.vendor_id)
          }
          else
          {
          
            setNoDataFound(true)
          }
        
          }
      catch(error){
          dispatch(set_isLoading({isLoading:false}))
       
          return(error) 
      }
  }

  const [vendorDetails, setVendorDetails] = useState(null)
  const getVendorDetailsURL ='api/customer/flyers'
  const getVendorDetails = async (token,vendorid) => 
  {
      dispatch(set_isLoading({isLoading:true}))
      try {
          const response = await axios.post(baseURL+getVendorDetailsURL,
            {vendor_id:vendorid ,start:0,length:12},{ headers: {"Authorization" : `Bearer ${token}`} });
          dispatch(set_isLoading({isLoading:false}))

          if(response.data.success)
          {
            setVendorDetails(response.data.data[0])
          }
          else
          {
            setNoDataFound(true)
          }
        
          }
      catch(error){

          dispatch(set_isLoading({isLoading:false}))
    
          return(error) 
      }
  }

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  

  const locationURL=window.location.href
  useEffect(() => {
    
    getFlyerDetails(access_token);
    scrollToTop();

   
   }, [loadLocation])
   

  return (
    <>
      <div className={isLoading ? 'parentDisableCommon' : ''} width="100%">

        <HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />

      </div>
      <button type="button" className="btn btn-outline-primary  mt-2 ms-2"  onClick={() => { navigate(-1);dispatch(set_loadLocation({loadLocation:!loadLocation}))}}><ArrowLeft/> Back</button>
    <div className='d-flex justify-content-center'>
    <div className='container p-2 bg-light mt-3 '>
        <div className='row p-0 m-0'>
          {noDataFound?
            <div className='col-12 p-3 d-flex align-items-stretch justify-content-center'>
          <div className="d-flex flex-column">
            <div className="p-2"><img src={noDataFoundimg} alt={noDataFoundimg} style={{maxWidth:'100%',maxHeight:'450px'}}/></div>
            <div className="p-2 text-center"> <h5>No Data Found !  </h5></div>
          </div>
    
        </div>:null}
           <div className='col-xl-6 col-12 '>
                 <>
                 <Swiper zoom={true} pagination={{clickable: true,}} navigation={true}   modules={[Zoom, Pagination, Navigation]} className="mySwiper">
                   <SwiperSlide>{flyerDetails?
                    <div className="swiper-zoom-container">

                      <img src={flyerImagePath+flyerDetails.thumb_image} alt={flyerDetails.title} style={{width:'579px'}}/>
                    </div>
                   
                   :null}</SwiperSlide>
                    {flyerDetails?
                      flyerDetails.flyers.map((flyer)=>
                        ((flyer.status==='active')?
                        <SwiperSlide key={flyer.id}> 
                         <div className="swiper-zoom-container">
                          <img src={flyerImagePath+flyer.flyers} alt={flyerDetails.title} style={{width:'579px'}}/>
                          </div> 
                          </SwiperSlide>:null
                          )):null}
                      
                             
                 </Swiper>
                 </>
                 </div>
                 {flyerDetails?
                  <div className='col-xl-6 col-12 p-3 mt-4'>
                    <div className='row'>
                      <h4>{flyerDetails.title}</h4>
                      <h6 >{vendorDetails?vendorDetails.shop_name:null}</h6>
                      <p className='text-secondary mt-1 mb-0' style={{fontSize:'0.9rem'}}>Description : {flyerDetails.description}</p>
                      <div className='row'>
                        <div className='col-12'>
                            <button type="button" className="btn btn-danger mt-1">Days Left: {getPlanDuration(flyerDetails.end_date)} </button>

                            <RWebShare
                              data={{
                                text: "Hey Look At This Deal I Got From IWILLFLY",
                                url: locationURL,
                                title: "IWILLFLY",
                              }}
                            >
                              <button type="button" className="btn btn-outline-primary mx-2 mt-1"><ShareFill/> Share</button>
                            </RWebShare>
  
                        </div>
                      </div>
                    </div>

                    <div className='row mt-4'>
                      <p className='text-secondary mt-1 mb-0' style={{fontSize:'0.9rem'}}>Similar Flyers Of Vendor</p>
                      <div className='row mt-2'>
                           {vendorDetails?(vendorDetails.flyers.length!==0)?

                          <div className='bg-light col-12 mt-1 text-center  '>      
                          <Swiper slidesPerView={1} spaceBetween={20} breakpoints= {{
                              // when window width is >= 320px
                              320: {
                              slidesPerView: 1,
                              spaceBetween: 20
                              },
                              // when window width is >= 480px
                              480: {
                              slidesPerView: 1,
                              spaceBetween: 20
                              },
                              // when window width is >= 640px
                              640: {
                              slidesPerView: 2,
                              spaceBetween: 20
                              }
                              }}
                              pagination={{
                              clickable: true,
                              }}
                              modules={[Pagination]}
                              className="mySwiper"
                          >
                            {vendorDetails.flyers.filter((flyer) => flyer.admin_approved === "1" && flyer.status === "active")
                            .map((flyer,index)=>
                                          {
                                          return(
                                          <SwiperSlide key={index}>
                                               <div className='col-xl-12  d-flex align-items-stretch justify-content-center' key={index}>
                                                          <Card sx={{ maxWidth: 331 ,width:331 }}  onClick={()=>{showDetailsOfFLyer(flyer.id,flyer.vendor_id,flyer.title,flyer.start_date,vendorDetails.shop_name)}}>
                                                              <CardHeader
                                                                avatar={
                                                                  <Avatar sx={{ bgcolor: '#fff'  }} aria-label="">
                                                                  <img src={vendorDetails.business_logo?businessImagePath+vendorDetails.business_logo:businessImg} alt='img' style={{maxWidth:"100%"}}/>
                                                                  </Avatar>
                                                                }
     
                                                                title={vendorDetails.shop_name}
                                                                subheader={vendorDetails.location}
                                                              />
                                                              <CardMedia
                                                                component="img"
                                                                height="468"
                                                                image={`${flyerImagePath}${flyer.thumb_image}`}
                                                                alt={flyer.title}
                                                              />
                                                              <CardContent>
                                                                <h5 className='text-break'>{flyer.title} </h5>
                                    
                                                                <Typography variant="body2" color="text.secondary ">
                                                                  <span className="d-inline-block text-truncate" style={{maxWidth: '100%'}}>
                                                                  {flyer.description}
                                                                  </span>  
                                                                </Typography>
                                                                  <Typography variant="body2" color="text.secondary">
                                                                  <span style={{color:"brown"}}> {getPlanDuration(flyer.end_date)} Days Left</span>
                                                                  </Typography>
                                                              </CardContent>
                                                            
                                                            </Card>
                                                            </div>
                                          </SwiperSlide>
                                          )
                                          }
                          )}
                          </Swiper>
                          </div>:<>
                          Sorry No Flyer Added</>:<></>}
                      </div>
                    </div>
                 </div>:null
                }
            

         </div>
     </div>
     </div>
    </>
  )
}

export default FlyerDetails