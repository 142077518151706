import axios from 'axios';
import React, { useState ,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {baseURL} from '../../Config';
import './SelectLocationModal.css'
import {useDispatch,useSelector} from "react-redux"
import {set_loadLocation} from "../../Redux/loadLocation/loadLocationSlice"
import { useForm ,Controller} from "react-hook-form";
import Select from 'react-select';
import  {GeoAltFill} from 'react-bootstrap-icons';
function SelectLocationModal() {
  const [show, setShow] = useState(true);

  const handleError = () => setFormError("Please Choose Location To Continue");
  const [formError, setFormError] = useState('');
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const dispatch =useDispatch()
  const access_token = JSON.parse(localStorage.getItem('access_token'));
  const loadLocation = useSelector(state => state.loadLocation.loadLocation);


  const [optionState,setOptionState]=useState([])
  const [selectedOptionState,setSelectedOptionState]=useState(null)
  const handleChangeState=(val)=>
  {
   setSelectedOptionState(val)
   setOptionDistrict([])
   setOptionLocation([])
   setStateError(false)

   
   getDistrictList(val)
   setSelectedOptionDistrict(null)
   setSelectedOptionLocation(null)
  }
  const getStateListURL='api/vendor/states'
  const getStateList=async()=>{
    try {
        const res = await axios.get(baseURL+getStateListURL , { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success)
        {
            //got all the states list here
            setOptionState([])
            res.data.data.map((state)=>( setOptionState(optionState=>[...optionState,{value:state.id, label:state.title}])   ))          
        }
        else
        {
            alert("Error Contact Administrator")
        }
    } catch (error) {
        return( error)
    }
    }



    const [optionDistrict,setOptionDistrict]=useState([])
    const [selectedOptionDistrict,setSelectedOptionDistrict]=useState(null)
    const handleChangeDistrict=(val)=>
    {
     setSelectedOptionDistrict(val)
     setOptionLocation([])
     getLocationList(val)
     setSelectedOptionLocation(null)
     setSelectedOptionLocationName(null)
     setDistrictError(false)

    }
    const getDistrictListURL='api/vendor/distirct/by/state'
    const getDistrictList=async(state_id)=>{
      try {
          const res = await axios.post(baseURL+getDistrictListURL , {state_id:state_id},{ headers: {"Authorization" : `Bearer ${access_token}`} })
          if(res.data.success)
          {

               //got all the states list here
               setOptionDistrict([])
               res.data.data.map((district)=>( setOptionDistrict(optionDistrict=>[...optionDistrict,{value:district.id, label:district.title}])   ))        
          }
          else{
              alert("Error Contact Administrator")
          }
      } catch (error) {
          return( error)
      }
      }
  


      const [optionLocation,setOptionLocation]=useState([])
      const [selectedOptionLocation,setSelectedOptionLocation]=useState(null)
      const [selectedOptionLocationName,setSelectedOptionLocationName]=useState(null)
      const handleChangeLocation=(val)=>
      {
       setSelectedOptionLocation(val.value)
       setSelectedOptionLocationName(val.label)
       setLocationError(false)
      }
    const getLocationListURL='api/vendor/location/by/district'
    const getLocationList=async(district_id)=>{
      try {
          const res = await axios.post(baseURL+getLocationListURL , {district_id:district_id},{ headers: {"Authorization" : `Bearer ${access_token}`} })
          if(res.data.success)
          {
              //got all the states list here
              setOptionLocation([])
              
              res.data.data.map((location)=>( setOptionLocation(optionLocation=>[...optionLocation,{value:location.id, label:location.title}])   ))        
          }
          else{
              alert("Error Contact Administrator")
          }
      } catch (error) {
          return( error)
      }
      }
  

const methods = useForm();
const { handleSubmit } = methods;
      


const [stateError,    setStateError] = useState(false)
const [districtError, setDistrictError] = useState(false)
const [locationError, setLocationError] = useState(false)


const submitHandler=(formData)=>
{



 if(selectedOptionState===null)
 {
   setStateError(true)
   
 }

 if(selectedOptionDistrict===null)
 {
   setDistrictError(true)
 
 }

 if(selectedOptionLocation===null)
 {
   setLocationError(true)
  
 }

 if(selectedOptionState&&selectedOptionDistrict&&selectedOptionLocation)
 {
   onSubmit()
 }


   
}

  //locatuion details submissoion
 const onSubmit=()=>
 {

   // var loc = data.location.split('-');
   // var location=loc[0];
   // var locationName=loc[1];
   const location_data = {'businessDistrict':selectedOptionDistrict,
   'businessState':selectedOptionState,
   'location':selectedOptionLocation,
   'locationName':selectedOptionLocationName 
 }
   localStorage.setItem('location_value', JSON.stringify(location_data));
   dispatch(set_loadLocation({loadLocation:!loadLocation}))

   
 }

    //locatuion details submissoion
    const setAllLocation=()=>
    {
      var locationName='All Location'
      const location_data = {'businessDistrict':null,
      'businessState':null,
      'location':null,
      'locationName':locationName,
       }
      localStorage.setItem('location_value', JSON.stringify(location_data));
      dispatch(set_loadLocation({loadLocation:!loadLocation}))
      
    }

 useEffect(() => {

 // getShopCategoryList(access_token);
 getStateList(access_token);
 }, [])

  return (
    <Modal
        show={show}
        onHide={handleError}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <form onSubmit={handleSubmit(submitHandler)} >
            <div className='col-12 '>
            <label className='mb-1 formLabelNew' >State</label>
              <Controller
                  control={methods.control}
                  // defaultValue={default_value}
                  
                  name="businessState"
                  render={({ field , value, name, ref }) => (
                      <Select
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          defaultValue={optionState[0]}
                          options={optionState}
                          value={selectedOptionState?selectedOptionState.value:'' || ''}
                          onChange={val => (field.onChange(val.value) ,handleChangeState(val.value)    )}
                          required
                          isSearchable={true}
                          placeholder='Choose State'
                      />
                  )}
              />
              {stateError&& <p className='mt-1 formErrorNew'>Please Choose State</p>}
            </div>
            <div className='col-12 mt-2'>
            <label className='mb-1 formLabelNew' >District</label>
              <Controller
                  control={methods.control}
                  // defaultValue={default_value}
                  name="businessDistrict"
                  render={({ field , value, name, ref }) => (
                      <Select
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={optionDistrict}
                          value={selectedOptionDistrict?selectedOptionDistrict.value:'' || ''}
                          onChange={val => (field.onChange(val.value),handleChangeDistrict(val.value))}
                          noOptionsMessage={() => (selectedOptionState)?'No State Found!':'Choose State First'}
                          isSearchable={true}
                          placeholder='Choose District'
                      />
                  )}
              />
            {districtError&& <p className='mt-1 formErrorNew'>Please Choose District</p>}



            </div>

            <div className='col-12 mt-2'>
            <label className='mb-1 formLabelNew' >Location</label>
              <Controller
                  control={methods.control}
                  // defaultValue={default_value}
                  name="location"
                  render={({ field , value, name, ref }) => (
                      <Select
                          inputRef1={ref}
                          classNamePrefix="addl-class"
                          options={optionLocation}
                          // value={selectedOptionLocation?selectedOptionLocation.value:''}
                          value={selectedOptionLocation?selectedOptionLocation.value:'' || ''}
                          onChange={val => (field.onChange(val.value),handleChangeLocation(val))}
                          noOptionsMessage={() => (optionDistrict.length===0)?'Choose District First':'No Location Found!'}
                          isSearchable={true}
                          placeholder='Choose Location'

                      />
                  )}
              />
              {locationError&& <p className='mt-1 formErrorNew'>Please Choose Location</p>}


            </div>

      

              </form>



       
        </Modal.Body>
        <Modal.Footer>
        
          <Button variant="primary align-items-center"  onClick={setAllLocation}> <div className='d-flex align-items-center'><GeoAltFill/> All Location</div>       </Button>

          <Button variant="primary"  onClick={handleSubmit(submitHandler)}>Sort Flyers {selectedOptionLocationName}</Button>
          <p className='mt-1 formErrorNew'>{formError?formError:null}</p>
         
          
        </Modal.Footer>
      </Modal>
      
  )
}

export default SelectLocationModal